// Nav.js
import "./nav.css";
import React from "react";

function Nav({headerName}) {
    return (
        <nav>
            <h1>{headerName}</h1>
        </nav>
    )
}

// export Nav
export default Nav;