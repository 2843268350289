// Footer.js
import React from "react";
import "./footer.css";

// social media icons
// import facebookIcon from "../images/socialicons/facebook-50.png";
//import instagramIcon from "../images/socialicons/instagram-50.png";
// import tiktokIcon from "../images/socialicons/tiktok-50.png";
//import twitterxIcon from "../images/socialicons/twitterx-50.png";
import linkedinIcon from "../images/socialicons/linkedin-30.png";
import githubIcon from "../images/socialicons/github-50.png";
// import youtubeIcon from "../images/socialicons/youtube-50.png";

function Footer({headerName}) {
    return (
        <footer>
            <div>
                <a href="https://www.linkedin.com/in/jamauni-taylor-902769189/" target="_blank"><img src={linkedinIcon} alt="LinkedIn"/></a>
                <a href="https://www.github.com/sirmauni" target="_blank"><img src={githubIcon} alt="GithubIcon"/></a>
            </div>
            <small>Jamauni Taylor &copy; | All Rights Reserved</small>
        </footer>
    )
}

// export Nav
export default Footer;