import './App.css';
import Nav from "./components/nav.js";
import Main from "./components/main.js";
import Footer from "./components/footer.js";
import axios from 'axios';
import { useEffect, useState } from 'react';

function App() {

  const [data, dataSet] = useState({
    headerName: "Name",
    heroDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  });

  const [projects, projectsSet] = useState({
    list: [{
      title: "project title",
      description: "project descriptions",
      link: "project link",
      link_description: "project link description"
    }]
  });

  const [workExp, workExpSet] = useState({
    list: [{
      jobTitle: "Job Title",
      companyTitle: "Company Name",
      startDate: "",
      endDate: "",
      jobDescription: "Brief description of job"
    }]
  });

  useEffect(() => {
    // get basic bio info
    axios.get("/api/bio").then((response) => {
      dataSet({
        ...data,
        headerName: response.data.result[0].display_name,
        heroDescription: response.data.result[0].bio
      });
    });
  },
  []
); 

  useEffect(
    () => {
      // get projects bio info
      axios.get("/api/projects").then((response) => {
        projectsSet({
          ...projects,
          list: response.data.result
        });
      });
    },
    []
  );

  useEffect(
    () => {
      // get work experience
      axios.get("/api/workExp").then((response) => {
        workExpSet({
          ...workExp,
          list: response.data.result
        });
      });
    },
    []
  );

  return (
    <>
      <Nav headerName={data.headerName}/>
      <Main heroDescription={data.heroDescription} projects={projects} workExp={workExp}/>
      <Footer />
    </>
  );
}

export default App;
